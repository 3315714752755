@import '../../styles/variables.scss';

.Login {  
  .btn {
    margin: 12px 0px;
  }

  .TextInput, .PhoneInput {
    margin-bottom: 15px;
    font-size: 12px;
  }
}