@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.NavigationHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
  background-image: url('../../assets/images/header_bg.jpg');
  background-size: 100% 100%;
  // background-color: $dark-blue;
  font-family: 'NHL-regular';

  &__logo {
    display: block;
    height: 50px;
    width: 85%;
    margin: 50px auto 30px auto;
    max-width: 792px;
    
    @include sm {
      height: 150px;
      // margin: 30px auto;
    }
  }

  &-links {
    text-align: center;
  
    &__link {
      display: inline-block;
      padding: 12px;
      font-size: 1.6em;
      color: white;
      box-sizing: border-box;
      position: relative;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
      }

      &:visited {
        color: $white;
      }
  
      @include sm {
        padding: 15px 30px;
      }
  
      &--active {
        font-family: 'NHL-medium';
        
        &::after {
          content: "";
          position: absolute;
          height: 5px;
          width: 50%;
          bottom: 0px;
          left: 25%;
          background: $yellow;
        }
      }

      &--unread {
        &::before {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          top: 7px;
          right: 7px;
          background: $yellow;

          @include sm {
            right: 22px;
          }
        }
      }
    }
  }
}