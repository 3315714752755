@import '../../styles/variables.scss';

.VerifyPhone {
  &__input {
    display: flex !important;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 10px 0px;
  
    input {
      color: $black;
      width: 100%;
      max-width: 60px;
      height: 65px;
      border: 2px solid $grey;
      text-align: center;
      font-size: 24px;
      outline: none;
    }
  
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      -mox-appearance: textfield;
      margin: 0; 
    }
  }

  &__error {
    color: $red;
  }

  &__resend {
    margin-top: 20px;
  }
}