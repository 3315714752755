@import './variables.scss';
@import './media.scss';

// Container Padding

@mixin container-padding {
  padding: $padding;
  @include sm {
    padding: $padding-sm;
  }
}

@mixin inner-padding {
  padding: 0px $padding;
  @include sm {
    padding: 0px $padding-sm;
  }
}