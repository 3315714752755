@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/reset.scss';

.MyStartingLineup {
  text-align: center;
  max-width: $max-width;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 4px;
  padding:20px;
  box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.3);

  @include md {
    margin: 20px auto;
    padding: 40px;
  }

  &__header {
    margin-bottom: 20px;
    font-size: 1.4em;
    @include md {
      font-size: 2em;
    }
  }

  &__image {
    width: 100%;
    min-height: 100px;
    margin-bottom: 20px;
  }

  .btn {
    margin: 0 auto;
  }
}