@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.BasicHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  background-color: $light-blue;
  background-size: 100% 100%;
  background-image: url('../../assets/images/header_bg.jpg');
  min-height: 210px;
  
  // @include sm {
  //   min-height:230px;
  // }

  &__logo {
    display: block;
    height: 50px;
    width: 85%;
    margin: 30px auto;
    max-width: 500px;

    @include sm {
      height: 70px;
      margin: 30px auto;
    }
  }
}