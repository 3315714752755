@import '../../styles/variables.scss';

.UserOffer {
  background: $white;
  color: $black;
  // border-radius: 4px;
  // box-shadow: 0 0 15px -1px rgba(0,0,0,.3);
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 10px 0 #00000036;
  // box-shadow: inset 0 10px 10px 0 rgba(0 0 0 0.05);
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  &-top-color {
    width: 100%;
    > svg {
      position: relative;
      top: -6px;
    }
    > div {
      background: $yellow;
      height: 10px;
    }
  }

  &-content {
    font-size: 1.2em;
    line-height: 1.2em;

    &__sender-logo, &__body, &__title {
      display: block;
    }

    &__title {
      font-size: 1.4em !important;
    }

    &__body {
      img {
        max-width: 100%;
      }
    }

    &__sender-logo {
      height: 70px;
      width: auto;
      color: black;
    }
  
    &-coupon {
      background: $grey;
      border-radius: 20px;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      max-width: 300px;
  
      &__code {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        font-size: 24px;
        margin-right: 5px;
      }
  
      &__copy {
        color: $dark-blue !important;
        background: transparent !important;
        padding: 0px !important;
        border: none !important;
        min-width: 0px !important;
      }
    }
  }

  &-bottom-color {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    
    > svg {
      position: absolute;
      top: -6px;
    }
    > div {
      background: $yellow;
      height: 20px;
    }
  }
}