@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.AgeVerification {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 10px;
  background-image: url('../../assets/images/age_verification_bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 99;
  box-sizing: border-box;
  color: $white;

  &-InnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 2px solid $blue;
    padding: 10px;
    box-sizing: border-box;

    &-maxwidth {
      text-align: center;

      @include sm {
        max-width: 70%;
      }
    }

    &--invalid-message {
      font-size: 24px;
      text-transform: uppercase;
      color: $yellow;
      border: 1px solid $yellow;
      padding: 14px 15px 10px;
      box-sizing: border-box;
      text-align: center;
    }

    &--title {
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &--subtitle {
      line-height: 1.1em;
      > span {
        font-weight: 700;
      }
    }

    &-inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0px;
      color: $black;

      .Dropdown-root {
        width: 100%;

        .Dropdown-placeholder {
          color: $black;
          text-transform: uppercase;
          font-size: 18px !important;
          line-height: 1em;
        }

        .Dropdown-control {
          border: none;
          border-radius: 0px;
        }
      }

      &--day {
        width: 3rem !important;
        margin: 0px 15px !important;
        font-size: 16px !important;
        padding: 8px 10px !important;
        border: none !important;
        line-height: 1em !important;
        border-radius: 0px !important;
      }

      &--year {
        width: 5rem !important;
      }

      .react-numeric-input {
        > input {
          font-size: 16px !important;
          padding: 8px 10px !important;
          border: none !important;
          line-height: 1em !important;
          border-radius: 0px !important;
        }
        > b {
          display: none;
        }
      }
    }
  }
}
