@import '../../styles/media.scss';
@import '../../styles/variables.scss';

.Offers {
  // padding: 15px;
  // max-width: 1000px;
  // margin: 0 auto;

  // @include sm {
  //   padding: 20px;
  // }

  &__empty {
    background: $white;
    // border-radius: 4px;
    box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.3);
  }

  &-list {
    // @include sm {
    //   overflow-y: auto;
    //   -webkit-overflow-scrolling: touch;
    //   max-height: calc(100vh - 220px);
    //   -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 95%, rgba(0,0,0,0));
    //   mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 95%, rgba(0,0,0,0));
    //   padding-bottom: 60px !important;
    // }

    &__item {
      // margin-bottom: 20px;
      position: relative;

      // &:last-of-type {
      //   margin-bottom: 0px;
      // }

      @include sm {
        &--active {
          background: #6EB6FF;

          @include sm {
            &::before {
              content: "";
              position: absolute;
              height: 98%;
              top: 1%;
              width: 5px;
              left: -12px;
              background: #6EB6FF;
            }
          }

          .UserOfferListItem-tags__exp {
            background-color: #C2DFFF;
          }

          .UserOfferListItem-tags__passport {
            background-color: #C2DFFF;
          }
        }
      }
    }
  }

  &-open {
    margin-left: 20px;
    display: none;

    @include sm {
      display: block;
    }
  }

  &-modal {
    border-radius: 1px;
    overflow: hidden;
  }
}