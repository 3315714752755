@import '../../styles/variables.scss';

.ResetPassword {
  .btn {
    margin: 12px 0px;
  }

  .TextInput {
    margin-bottom: 10px;
    font-size: 12px;
  }
}