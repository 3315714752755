@import '../../styles/variables.scss';

.Stepper {
  display: flex;
  justify-content: space-between;
}

.Stepper-step {
  display: block;
  width: 10px;
  height: 10px;
  background: $grey;
  transition: width 0.3s ease-in;

  &--active {
    width: 30px;
    background: $yellow;
  }
}