@import '../../styles/variables.scss';

.FormFields-field {
  padding-top: 20px;

  &__title {
    display: block;
    text-align: left;
    padding: 5px 0px;
  }

  &__dropdown {
    width: 100%;

    .Dropdown-control {
      cursor: pointer;
      background: $grey;
      color: $black;
    }

    .Dropdown-menu {
      background: $grey;
    }
  
    .Dropdown-arrow {
      top: 45%;
    }

    .Dropdown-option, .Dropdown-placeholder {
      display: flex;
      align-items: center;
    }
  
    .Dropdown-option .background-image, .Dropdown-placeholder .background-image {
      background-color: transparent !important;
      width: 40px !important;
      height: 40px !important;
    }
  
    .Dropdown-option label, .Dropdown-placeholder label {
      padding-left: 15px;
    }
  }

  &__checkbox {
    padding: 5px 0px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;

    label {
      cursor: pointer;
      padding-left: 10px;
      text-align: left;
    }

    .checkbox__box {
      border: 1px solid $yellow;

      &--checked {
        background-color: $yellow;
      }
    }
  }
}