@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/mixins.scss';
@import '../../styles/font.scss';
@import '../../styles/reset.scss';

.Account {
  max-width: $max-width;
  box-sizing: border-box;
  margin: 0 auto;
  color: $black;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.3);
  @include container-padding;

  @include md {
    margin: 20px auto;
  }
}

.Account-settings {
  .btn {
    margin-top: 20px;
  }

  &__title {
    padding-top: 30px;
    border-bottom: 1px solid $light-blue;
    color: $black;
    font-family: "NHL-regular";

    &:first-of-type {
      padding-top: 20px;
    }
  }
}

.Account-settings-field {
  margin-top: 15px;
  margin-bottom: 15px;

  &__label {
    display: block;
    padding-bottom: 5px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__input {
    padding: 14px !important;
    background: $grey;
    font-size: 14px;
    overflow: hidden;
    border-radius: 2px;
    border: 1px solid $dark-grey !important;

    &--phone {
      padding: 8px !important;
      width: 100%;
      input {
        background-color: $grey;
      }
    }
  }

  &__avatar {
    margin-left: 15px;
    border: 1px solid $blue;
    &:hover {
      text-decoration: none;
    }
  }
}