
@import '../../styles/variables.scss';

.site-footer {
    background-color: #011e41;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: $font-family;
    font-size: 16px;
        line-height: 1;
}

.site-footer__container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px
}

.site-footer__container>.navbar-header,
.site-footer__container>.navbar-collapse {
    margin-right: -10px;
    margin-left: -10px
}

@media (min-width: 568px) {

    .site-footer__container>.navbar-header,
    .site-footer__container>.navbar-collapse {
        margin-right: 0;
        margin-left: 0
    }
}

.site-footer__container:before,
.site-footer__container:after {
    content: " ";
    display: table
}

.site-footer__container:after {
    clear: both
}

.site-footer__container:before,
.site-footer__container:after {
    content: " ";
    display: table
}

.site-footer__container:after {
    clear: both
}

@media (min-width: 1308px) {
    .site-footer__container {
        width: 1284px
    }
}

.site-footer__heading {
    font-size: 20px;
    color: #ffffff
}

.site-footer__links-container {
    margin-left: -10px;
    margin-right: -10px
}

.site-footer__links-container:before,
.site-footer__links-container:after {
    content: " ";
    display: table
}

.site-footer__links-container:after {
    clear: both
}

.site-footer__links-container:before,
.site-footer__links-container:after {
    content: " ";
    display: table
}

.site-footer__links-container:after {
    clear: both
}

.site-footer__link-container {
    float: left;
    width: 100%;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 12px
}

@media (min-width: 568px) {
    .site-footer__link-container {
        float: left;
        width: 50%
    }
}

@media (min-width: 1024px) {
    .site-footer__link-container {
        float: left;
        width: 25%
    }
}

.site-footer__link-container .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow: auto
}

.site-footer__hr {
    border: 0;
    border-top: 1px solid #666666;
    margin-top: 12px;
    margin-bottom: 12px
}

.site-footer__legal-links {
    margin-bottom: 12px;
    text-align: center
}

.site-footer__legal-link {
    color: #999999;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
    margin-top: 12px;
    text-transform: uppercase
}

.site-footer__legal-link:hover,
.site-footer__legal-link:focus {
    text-decoration: none
}

.site-footer__legal-link:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.site-footer__legal-link:hover,
.site-footer__legal-link:focus {
    color: #ffffff
}

@media screen and (min-width: 768px) {
    .site-footer__legal-link {
        font-size: 16px;
        margin-right: 20px
    }
}

.site-footer__sponsor-heading {
    margin-left: 20px
}

.site-footer__sponsor-links {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    text-align: center
}

.site-footer__sponsor-link {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 24px;
    width: 100px;
    height: 50px
}

.site-footer__colophon-container {
    width: 100%
}

.site-footer__shield-link {
    display: block;
    float: left;
    margin-right: 32px;
    margin-bottom: 32px;
    width: 50px
}

@media screen and (min-width: 768px) {
    .site-footer__shield-link {
        width: 100px
    }
}

.site-footer__shield-logo {
    background-repeat: no-repeat;
    display: block;
    width: 50px;
    height: 56px
}

@media screen and (min-width: 768px) {
    .site-footer__shield-logo {
        width: 100px;
        height: 113px
    }
}

.site-footer__colophon {
    float: right;
    color: #D2D2D2;
    font-size: 10px;
    width: calc(100% - 85px);
    margin: 0 0 8px;
    line-height: 1.75;
}

@media screen and (min-width: 768px) {
    .site-footer__colophon {
        width: calc(100% - 133px);
        font-size: 12px;
        -ms-flex: 1
    }
}

.site-footer[data-is-club] .site-footer__colophon-container {
    display: inline-block;
    margin-bottom: 12px
}

@media screen and (min-width: 768px) {
    .site-footer[data-is-club] .site-footer__colophon-container {
        margin-bottom: 20px
    }
}

.site-footer[data-is-club] .site-footer__colophon {
    width: auto
}

@media screen and (min-width: 768px) {
    .site-footer[data-is-club] .site-footer__colophon {
        width: calc(100% - 220px)
    }
}

.site-footer[data-is-club] .site-footer__team-link {
    display: block;
    width: 105px;
    margin: 8px auto
}

@media screen and (min-width: 768px) {
    .site-footer[data-is-club] .site-footer__team-link {
        width: 210px;
        float: left;
        height: 130px;
        // padding: 10px 20px;
        margin: 0
    }
}

.site-footer[data-is-club] .site-footer__team-logo {
    display: block;
    width: 105px;
    height: 70px
}

@media screen and (min-width: 768px) {
    .site-footer[data-is-club] .site-footer__team-logo {
        height: 100%;
        width: 100%
    }
}

.site-footer[data-is-club] .site-footer__legal-link {
    color: #999999
}

.site-footer[data-is-club] .site-footer__legal-link:hover,
.site-footer[data-is-club] .site-footer__legal-link:focus {
    text-decoration: none
}

.site-footer[data-is-club] .site-footer__legal-link:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.site-footer[data-is-club] .site-footer__legal-link:hover,
.site-footer[data-is-club] .site-footer__legal-link:focus {
    color: #ffffff
}

.site-footer[data-is-club] .site-footer__legal-links {
    display: block;
    margin-bottom: 8px
}

@media screen and (min-width: 768px) {
    .site-footer[data-is-club] .site-footer__legal-links {
        float: right;
        text-align: left;
        margin-bottom: 12px;
        width: calc(100% - 220px)
    }
}

.site-footer[data-is-club] .site-footer__nhlcom-logo {
    outline: 0;
    display: block;
    margin: 8px auto;
    position: relative;
    height: 40px;
    width: 210px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.site-footer[data-is-club] .site-footer__nhlcom-logo::-moz-focus-inner {
    border: 0
}

.site-footer[data-is-club] .site-footer__nhlcom-logo:focus {
    outline: 0
}