@import '../../styles/variables.scss';

.RegistrationForm {
  &__error {
    display: flex;
    padding: 5px;
    font-size: 12px;
    color: $red;
  }

  &__names {
    display: flex;

    .TextInput {
      flex-grow: 1;
    }
  }

  &__spacer {
    width: 20px;
  }

  .TextInput, .PhoneInput {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .checkbox {
    padding: 10px 0px;

    &__box {
      border: 1px solid $grey;

      &--checked {
        background-color: $yellow;
      }
    }

    label {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}