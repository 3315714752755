@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.Menu {
  // padding-top: 50px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1199;

  &--condensed {
    position: relative;
    margin-top: 0px;
  }
  
  &-index {
    outline: none;
  }

  &-list {
    width: 180px;
  }

  &-list-item {
    display: block;
    padding: 15px 30px;
    font-size: 18px;
    border-left: 5px solid transparent;
    border-bottom: 1px solid grey;
    color: black;
    cursor: pointer;
    outline: none;

    &--active {
      border-left: 5px solid gold;
    }

    &--logout {
      color: red;
      border-bottom: none;
    }
  }

  &-nav {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 2px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

    &--scrolled {
      background: $light-blue;
    }

    &--relative {
      position: relative;
    }

    &--condensed {
      position: relative;
      background: $light-blue;
      padding: 8px 8px 0px 2px;
      
      &__link {
        display: inline-block;
        padding: 12px;
        padding-bottom: 15px;
        font-size: 1.4em;
        color: white;
        box-sizing: border-box;
        position: relative;
        text-transform: uppercase;
        font-family: 'NHL-regular';
  
        &:hover {
          text-decoration: none;
        }
  
        &:visited {
          color: $white;
        }
  
        @include sm {
          padding: 15px 30px;
          font-size: 1.6em;
        }
  
        &--active {
          font-family: 'NHL-medium';
          &::after {
            content: "";
            position: absolute;
            height: 5px;
            width: 50%;
            bottom: 0px;
            left: 25%;
            background: $yellow;
          }
        }
  
        &--unread {
          &::before {
            content: "";
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            top: 7px;
            right: 7px;
            background: $yellow;
  
            @include sm {
              right: 22px;
            }
          }
        }
      }
    }

    &__logo-link {
      display: inline-block;
      position: relative;

      &__img--logomark {
        width: 50px;
        height: 34px;
        border: 0;
        vertical-align: middle;
      }
      &__img--logo {
        padding-left:20px;
        height: 20px;
        width:163px;
        border: 0;
        vertical-align: middle;
      }
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    font-size: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 999;
    transition: .25s;
    background: transparent;

    i {
      color: $yellow;
    }

    &:hover {
      text-decoration: none;
      background-color: $white;
      i {
        color: $blue;
      }
    }
  }
}