@import '../../styles/variables.scss';

.TextInput {
  border: 1px solid $dark-grey;
  padding: 10px;
  position: relative;

  input {
    border: none;
    outline: none;
    padding: 5px;
    width: 100%;
    font-size: 1.4em;
  }

  &__error {
    position: absolute;
    top: 2px;
    left: 5px;
    color: $red;
    font-size: 10px;
  }
}

.TextInput-input-wrapper {
  display: flex;
  align-items: center;

  &__verified { 
    color: $green;
    font-size: 16px;
    padding: 0px 10px;
  }
}

.TextInput--password {
  padding: 0px;
  border: none !important;

  input {
    margin: 15px !important;
  }

    .TextInput-input-wrapper {
      border: 1px solid $dark-grey;
    }
}