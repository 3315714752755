@import '../../styles/variables.scss';

.UserOfferListItem {
  background: $white;
  color: $black;
  // border-radius: 4px;
  // padding: 15px;
  // box-shadow: 0 0 15px -1px rgba(0,0,0,.3);
  // cursor: pointer;'
  border-bottom: 1px solid $grey;

  &-sender, &-subject {
    margin-bottom: 15px;
  }

  &-sender {
    &__avatar {
      background-color: $dark-blue;
      color: $white;
      margin-right: 10px;
    }
  }

  &-tags {
    &__exp {
      background: $grey;
      padding: 10px 10px 7px;
      font-size: 12px;

      > span {
        color: $btn-disabled-fc;
        margin-right: 5px;
      }
    }

    &__passport {
      background: $grey;
      padding: 10px 10px 7px;
      font-size: 12px;

      > span {
        color: $btn-disabled-fc;
        margin-right: 5px;
      }
    }
  }
}