/**************
  RESET
**************/
html, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
b {
  font-weight: 600;
}
a {
  text-decoration: none;
}
textarea {
  border: none;
  width: 100%;
  padding: 5px;

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled, &.disabled {
    cursor: not-allowed;
  }
}
input[type="password"],
input[type="text"],
input[type="email"],
input[type="date"],
input[type="datetime"],
input[type="search"],
input[type="url"],
input[type="tel"] {
  width: 100%;
  padding: 5px;
  border: none;
  outline: none;

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled, &.disabled {
    cursor: not-allowed;
  }

  &::-ms-clear {
    display: none;
  }
}
