@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/reset.scss';
@import './styles/media.scss';
@import './styles/font.scss';
@import './styles/variables.scss';

html, body {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  background-color: #e7e9e9;
    // background: linear-gradient(to bottom, $dark-blue, $blue);
      /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  background-image: url('./assets/images/bg.jpg');
  background-attachment: fixed;
  background-position: top;
  background-size: 100% 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.in-app {
  margin-top: 50px;
}

a, a:visited {
  color: $dark-blue;
  transition: color 0.1s ease;
}
a:active, a:hover {
  color: #6886bb;
}

.link {
  color: $dark-blue;
  transition: color 0.1s ease;
}

.link:active,
.link:hover {
  color: #6886bb;
}

.link-color {
  color: $dark-blue;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 2em;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.icon-loading {
  display: inline-block;
  animation: spin 2.25s linear infinite;
}
.glyph {
  font-size: 16px;
  width: 15em;
  padding-bottom: 1em;
  margin-right: 4em;
  margin-bottom: 1em;
  float: left;
  overflow: hidden;
}
.talign-right {
  text-align: right;
}
.talign-center {
  text-align: center;
}
.size1of2 {
  width: 50%;
}
.size1of1 {
  width: 100%;
}
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.hidden-true {
  display: none !important;
}

.Page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ActivityCard {
  box-shadow: 0 0 50px -15px rgba(0, 0, 0, 0.5);
}

.btn, a.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  font-weight: normal;
  // font-style: italic;
  font-family: 'NHL-regular';
  line-height: 1em;
  padding: 12px 12px 12px;
  outline: none;
  transition: background-color 0.25s ease, color 0.25s ease;
  background-color: $light-blue;
  border: none;
  box-shadow: none;
  font-size: 1.6em;
  color: $white;
  position: relative;

  &:not([disabled]) {
    @include phone {
      &:hover, &:active {
        color: $white;
        background-color: $btn-hover;
      }
    }
  }

  &.CopyButton, &.EmailShare, &.FacebookShare, &.FacebookMessengerShare, &.TwitterShare, &.SmsShare {
    font-size: 14px;
  }

  &--small {
    font-size: 1.3em;
  }

  &--facebook {
    background: $fb-blue;
  }

  &--google {
    background: $google-blue;
  }

  &--yellow {
    background: $yellow;
    color: $dark-blue;

    &:not([disabled]) {
      @include phone {
        &:hover, &:active {
          color: $dark-blue;
          background-color: #ffd76c;
        }
      }

      &.btn--stretch {
        &::after {
          border-color: transparent transparent $dark-blue transparent;
        }
      }
    }
  }

  &--secondary {
    border: 2px solid $light-blue;
    background: white !important;
    color: $light-blue !important;

    &:hover {
      border: 2px solid $btn-hover;
      color: $btn-hover !important;
    }
  }

  &:disabled {
    background: $btn-disabled !important;
    color: $btn-disabled-fc !important;
  }

  &--stretch {
    width: 100%;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   right: 5px;
    //   bottom: 5px;
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    //   border-width: 0 0 16px 16px;
    //   border-color: transparent transparent #e0e0e0 transparent;
    // }
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: auto;
  }

  &--icon {
    img, i {
      margin-right: 5px;
    }
  }

  label {
    cursor: pointer;
  }
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;

  &--sm {
    height: 20px;
    width: 20px;
    font-size: 12px;
  }

  &--md {
    height: 30px;
    width: 30px;
    font-size: 16px;
  }

  &--lg {
    height: 65px !important;
    width: 65px !important;
    font-size: 24px;
  }
}

.title {
  font-size: 20px;
  line-height: 1.2em;
}

.subtitle {
  font-size: 16px;
}

.markdown {
  p {
    line-height: 1.6em;
    margin: 0px 0px 1em;
    color: #333333;
  }

  strong {
    font-weight: 700;
  }

  ol, ul {
    padding-left: 1em;
    margin-top: 1.5em;
    margin-bottom: 1em;
    list-style: inherit;
  }

  ol, ul {
    li {
      color: #333333;
      margin-left: .5em;
      margin-bottom: 0.5em;
      line-height: 1.6em;

      p strong {
        color: $black;
      }
    }

    > li > ol {
      list-style-type: lower-alpha;
      > li > ol {
        list-style-type: lower-roman;
        li {
          margin-bottom: .5em;
        }
      }
    }
  }
  table {
    width: 100%;
    margin-bottom: 1em;
    th {
      background-color: #e0e0e0;
    }
    th, td {
      border: solid 1px #b9b8b8;
      padding: 4px;
    }
  }
}

.deal-markdown {
  p {
    line-height: 1em;
    margin: 0px 0px 1em;
    color: #333333;
  }

  strong {
    font-weight: 700;
  }

  ol, ul {
    list-style: inherit;
  }

  ol, ul {
    li {
      color: #333333;
      margin-left: .7em;
      margin-bottom: 0.5em;

      p strong {
        color: $black;
      }
    }

    > li > ol {
      list-style-type: lower-alpha;
      > li > ol {
        list-style-type: lower-roman;
        li {
          margin-bottom: .5em;
        }
      }
    }
  }
}

header {
  text-align: center;
  img {
    width: 100%;
    max-width:500px;
  }
}

.poll {
  .poll__header {
    color: $blue;
    font-size: 24px;
  }

  .checkbox__box {
    height: 30px;
    width: 30px;
    border: 1px solid $yellow;

    &--checked {
      background-color: $yellow;
    }
  }
}

.Empty {
  text-align: center;
  padding: 40px 10px;
  font-size: 20px;
}

.bg-blues-yellow {
  background-color: $yellow;
}

.border-blues-yellow {
  border-color: $yellow;
}

.text-blues-yellow {
  color: $yellow;
}

.link-blues-yellow {
  color: $yellow !important;

  &:hover {
    color: #ffd76c !important;
  }
}

.bg-blues-blue {
  background-color: $dark-blue;
}

.bg-blues-light-blue {
  background-color: $light-blue;
}

.text-blues-light-blue {
  color: $light-blue;
}

.text-blues-blue {
  color: $dark-blue;
}