@import '../../styles/variables.scss';

.Checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--disabled {
    cursor: not-allowed;

    &__box {
      border: 1px solid $btn-disabled;
    }
  }

  &__box {
    min-height: 20px;
    min-width: 20px;
    border: 1px solid $blue;
    display: flex;
    justify-content: center;
    align-items: center;

    > i {
      color: $white;
      display: none;
      font-size: 12px;
    }

    &--checked {
      background-color: $blue;
      > i {
        display: block;
      }
    }
  }
}