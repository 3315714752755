$small-phone-width: 375px;
$medium-phone-width: 414px;
$phone-width: 480px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-desktop-width: 1200px;

$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

@mixin sm {
  @media only screen and (min-width: #{$sm}) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: #{$md}) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: #{$xl}) {
    @content;
  }
}

@mixin small-phone {
  @media only screen and (min-width: #{$small-phone-width}) {
    @content;
  }
}

@mixin medium-phone {
  @media only screen and (min-width: #{$medium-phone-width}) {
    @content;
  }
}

@mixin phone {
  @media only screen and (min-width: #{$phone-width}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media only screen and (min-width: #{$large-desktop-width}) {
    @content;
  }
}

//Broswer Targeting
@mixin mozilla {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin microsoft {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
