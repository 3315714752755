@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/mixins.scss';

.PassportSignUp {
  padding: 0px !important;

  .btn--secondary {
    color: $light-blue !important;
  }
}