@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/reset.scss';

.Rules {
  max-width: $max-width;
  margin: 0 auto;
  color: $black;

  word-wrap: break-word;

  background: #ffffff;
  border-radius: 4px;
  padding:20px;
  box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.3);

  @include md {
    margin: 20px auto;
    padding: 40px;
  }

  a, a:visited {
    color: $light-blue;
  }
  a:active, a:hover {
    text-decoration: underline;
  }
}