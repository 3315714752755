@import '../../styles/variables.scss';

.PhoneInput {
  border: 1px solid $dark-grey;
  padding: 10px;
  position: relative;

  input {
    border: none;
    outline: none;
    padding: 5px;
    width: 100%;
    height: auto;
    font-size: 16px;
  }

  &__error {
    position: absolute;
    top: 2px;
    left: 5px;
    color: $red;
    font-size: 10px;
  }
}