@import '../../styles/variables.scss';

.Footer {
  padding: 30px 10px;
  text-align: center;
  &__links {
    margin-top: 10px;
    a {
      font-size: 18px;
      // &, &:visited {
      //   color: $light-blue;
      // }
      // &:hover, &:active {
      //   text-decoration: underline;
      // }
    }
  }
  &__img {
    height: 18px;
  }
}
