@import '../../styles/variables.scss';

.ListOffer {
  background: $white;
  color: $black;
  border-radius: 4px;
  box-shadow: 0 0 15px -1px rgba(0,0,0,.3);
  position: relative;
  overflow: hidden;

  &-top-color {
    width: 100%;
    > svg {
      position: relative;
      top: -6px;
    }
    > div {
      background: $yellow;
      height: 10px;
    }
  }

  &-content {
    text-align: center;
    padding: 1.5em;
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &__sender-logo, &__body, &__title {
      display: block;
    }

    &__sender-logo {
      height: 70px;
      width: auto;
      color: black;
      margin: 0 auto 30px auto;
    }
  
    &-coupon {
      background: $grey;
      border-radius: 20px;
      height: 40px;
      margin: 0 auto;
      text-align: center;
      max-width: 300px;
      font-size: 36px;
      box-sizing: border-box;
      position: relative;

      > span {
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
      }
    }

    &__btn {
      max-width: 300px;
      width: 100%;
      margin-top: 20px;
    }
  }

  &-bottom-color {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    
    > svg {
      position: absolute;
      top: -6px;
    }
    > div {
      background: $yellow;
      height: 20px;
    }
  }
}