@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/reset.scss';

.navbar {
  background: $blue;
  color: $yellow;
  font-size: 18px;

  a {
    color: $yellow;
  }

  &__right {
    .icon-chevron-right {
      margin-left: 3px;
      font-size: 12px;
    }
  }
}

.Activity {
  max-width: $max-width;
  margin: 0 auto;
  width: 100%;
}
