@font-face {
  font-family: 'tunespeak';
  src:
    url('fonts/tunespeak.ttf?seq2si') format('truetype'),
    url('fonts/tunespeak.woff?seq2si') format('woff'),
    url('fonts/tunespeak.svg?seq2si#tunespeak') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tunespeak' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mobile-alert:before {
  content: "\e918";
}
.icon-twitter:before {
  content: "\e917";
}
.icon-check-in:before {
  content: "\e915";
}
.icon-website:before {
  content: "\e908";
}
.icon-address:before {
  content: "\e910";
}
.icon-hours:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e913";
}
.icon-edit:before {
  content: "\e904";
}
.icon-release:before {
  content: "\e902";
}
.icon-discount:before {
  content: "\e901";
}
.icon-share:before {
  content: "\e900";
}
.icon-i-message:before {
  content: "\e935";
}
.icon-fb-messenger:before {
  content: "\e936";
}
.icon-clipboard:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e90c";
}
.icon-facebook:before {
  content: "\e919";
}
.icon-mail:before {
  content: "\e922";
}
.icon-circle-play:before {
  content: "\ea15";
}
.icon-loading:before {
  content: "\e914";
}
.icon-chevron-right:before {
  content: "\e90a";
}
.icon-youtube:before {
  content: "\e91e";
}
.icon-spotify:before {
  content: "\e91a";
}
.icon-instagram:before {
  content: "\e91c";
}
.icon-itunes:before {
  content: "\e958";
}
.icon-photo:before {
  content: "\e921";
}
.icon-music:before {
  content: "\e924";
}
.icon-album:before {
  content: "\e931";
}
.icon-video:before {
  content: "\e967";
}
.icon-lock:before {
  content: "\e903";
}
.icon-lyrics:before {
  content: "\e90b";
}
.icon-settings-spotify:before {
  content: "\e939";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e90d";
}
.icon-x:before {
  content: "\e90e";
}
.icon-power:before {
  content: "\e92a";
}
.icon-google:before {
  content: "\ea88";
}
.icon-chevron-left:before {
  content: "\e909";
}
.icon-clock:before {
  content: "\e927";
}
.icon-raffle-box:before {
  content: "\e93e";
}
.icon-circle:before {
  content: "\e954";
}
.icon-ticket:before {
  content: "\e92f";
}
.icon-plus:before {
  content: "\e932";
}
.icon-help:before {
  content: "\e911";
}
.icon-circle-check:before {
  content: "\e923";
}
.icon-circle-x:before {
  content: "\e916";
}
