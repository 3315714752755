@import './variables.scss';

@font-face {
  font-family: 'NHL';
  src: url('../assets/fonts/NHL.woff2') format('woff2');
}

@font-face {
  font-family: 'NHL-regular';
  src: url('../assets/fonts/Monte Stella Regular.ttf');
}

.nhl-regular {
  font-family: 'NHL-regular';
}

@font-face {
  font-family: 'NHL-italic';
  src: url('../assets/fonts/Monte Stella Regular Italic.ttf');
}

.nhl-italic {
  font-family: 'NHL-italic';
}

@font-face {
  font-family: 'NHL-bold';
  src: url('../assets/fonts/Monte Stella Bold.ttf');
}

.nhl-bold {
  font-family: 'NHL-bold';
}

@font-face {
  font-family: 'NHL-medium';
  src: url('../assets/fonts/Monte Stella Medium.ttf');
}

.nhl-medium {
  font-family: 'NHL-medium';
}

@font-face {
  font-family: 'NHL-light';
  src: url('../assets/fonts/Monte Stella Light.ttf');
}

.nhl-light {
  font-family: 'NHL-light';
}

@font-face {
  font-family: 'Scoreboard';
  src: url('../assets/fonts/Scoreboard.woff2') format('woff2');
}

.font-regular {
  font-family: 'NHL', $font-family;
}

@font-face {
  font-family: 'SignPainter';
  src: url('../assets/fonts/SignPainter.ttc');
}

.signpainter {
  font-family: 'SignPainter';
}