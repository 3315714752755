@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.ActivityCard {
  background-color: #FFF;
  width: 100%;

  @include tablet {
    &--fourth {
      width: 25%;
    }

    &--third {
      width: 33%;
    }

    &--half {
      width: 50%;
    }
  }

  .rpad {
    padding: 10px;
    @include phone {
      padding: 20px;
    }
  } 
}