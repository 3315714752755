@import '../../styles/media.scss';

.ActivityGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  .ActivityCard {
    margin-bottom: 1.2em;
    @include sm {
      margin-bottom: 1.5em;
    }

    @include tablet {
      &--fourth {
        width: 23%;
      }
      &--third {
        width: 32%;
      }
      &--half {
        width: 48%;
      }
    }
  }
}
