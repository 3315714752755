@import '../../styles/variables.scss';
@import '../../styles/media.scss';

.SignIn-or-divider {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  &__line {
    height: 1px;
    background: $black;
    flex-grow: 1;
  }

  &__or {
    margin-top: 3px;
    width: 50px;
    font-size: 1.2em;
    // font-weight: 700;
    text-align: center;
  }
}

.SignUpFlow {
  max-width: 520px;
  min-height: 40vh;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.3);
  font-family: $font-family;
  font-size: 16px;
  line-height: 1;

  @include md {
    margin: 30px auto 0;
    border-radius: 4px;
  } 

  a, a:visited {
    color: $blue;
  }
  a:active, a:hover {
    text-decoration: underline;
  }

  h2 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: 'NHL-regular';
  }

  p {
    margin-bottom: 15px;
    line-height: 1.2em;
  }

  .Stepper {
    width: 100px;
    margin: 0 auto;
    padding-top: 15px;
  }

  .btn, a.btn {
    color: $white;
    margin: 12px 0px;
    text-transform: uppercase;
  }

  &__login-change {
    text-align: justify;
    line-height: 1.2em;
    background-color: #F0F0F0;
    padding: 10px;
    border-radius: 3px;
    margin-top: 15px;

    a, a:visited {
      color: $light-blue;
    }
    a:active, a:hover {
      text-decoration: underline;
    }
  }

  &__legal {
    margin-top: 15px;
    font-size: 12px;
    color: #777;
    text-align: justify;
    line-height: 1.2em;

    a, a:visited {
      color: $light-blue;
    }
    a:active, a:hover {
      text-decoration: underline;
    }
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    a, a:visited {
      color: $light-blue;
    }
    a:active, a:hover {
      text-decoration: underline;
    }
  }
}

.OAuthFlow {
  min-height: 100vh;
  justify-content: start;
  margin: 0 auto !important;

  h2 {
    text-align: left;
    font-size: 24px;
    img {
      display: block;
      margin-top:5px;
      max-width: 240px;
    }
  }
  p {
    text-align: left;
  }
}