$max-width: 800px;
$font-family: 'NHL', ui-sans-serif,
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";
// $font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$red: #EF2A2B;
$yellow: #f0b847;
$grey: #f2f2f2;
$light-grey: #f2f2f2;
$dark-grey: #d9d9d9;
$fb-blue: #004e95;
$google-blue: #4885ed;
$green: #4AB16F;
$dark-blue: #002e80;
$blue: #00184A;
$light-blue: #003A84;
$black: #010100;

$btn: #00184A;
$btn-hover: $blue;
$btn-disabled: #d4d4d4;
$btn-disabled-fc: #a1a1a1;

$darker-red :#e31837;
$darker-green: #3cb371;
$bg-body: #e59704;
$white: #FFF;

$padding: 20px;
$padding-sm: 30px;

.bg-ts-dark-grey {
  background-color: $dark-grey;
}
