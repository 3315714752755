@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/reset.scss';

.navbar {
  background: $blue;
  color: $yellow;
  font-size: 18px;

  a {
    color: $yellow;
  }

  &__right {
    .icon-chevron-right {
      margin-left: 3px;
      font-size: 12px;
    }
  }
}

.dashboard {
  max-width: $max-width;
  margin: 0 auto;
  color: $black;
  // padding: 30px 5px;
  
  // @include sm {
  //   padding: 30px 15px;
  // }

  .ActivityCard--type-TunespeakRaffle {
    width: 100%;
  }

  &-placeholder-img {
    height: 100%;
    width: 100%;
  }
}

.dashboard-coming-soon {
  &__header {
    text-align: center;
    color: $yellow;
    background: $white;
    padding: 30px;
    font-size: 24px;
  }

  &__fade {
    height: 150px;
    background: linear-gradient(to bottom, $white, transparent);
  }
}