@import '../../styles/variables.scss';
@import '../../styles/media.scss';
@import '../../styles/mixins.scss';

.tbbb-sidebar {
  width: 18rem;
  transform: translateX(-100%);

  @include sm {
    width: 26rem;
    transform: translateX(0%); 
  }

  &--active {
    transform: translateX(0%);
  }
}

.bars-list-item {
  &:hover {
    background-color: #FFF69D !important;
  }
}